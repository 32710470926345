<template>
  <div class="home"
    @scroll="scroll">
      <div class="a1" v-if="screen !== 8"
        @click="toscreen(1)">
        <img :src="a1" />
      </div>
      <div class="side">
        <div class="btn up"
          @click="totop">
          <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/top.png" />
        </div>
        <div class="btn mail"
          @click="() => {topage('http://www.maxim-group.cn')}">
          <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w2.png" />
          <div class="pop">
            <span>返回美声官网</span>
          </div>
        </div>
        <div class="btn cs"
         @click="() => {tous()}">
          <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w3.png" />
          <div class="pop">
            <div class="code">
              <img :src="contact" />
              <!-- <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/qrcode.jpeg" /> -->
              <div class="codetip">扫一扫联系商务</div>
            </div>
          </div>
        </div>
        <div class="btn app"
         @click="() => {topage('https://ecotrac.cn/download/')}">
          <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/app.png" />
          <div class="pop">
            <div class="code">
              <img :src="down" />
              <!-- <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/qrcode.jpeg" /> -->
              <div class="codetip">扫一扫下载APP</div>
            </div>
          </div>
        </div>
        <div class="btn pcicon"
          @click="() => {topage('https://ecotrac.cn/Business/#/login')}">
          <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w17.png" />
          <div class="pop">
            <span>登入EcoTrac平台</span>
          </div>
        </div>
      </div>
      <div class="pages"
        :style="{
          transform: getTrans()
        }">
        <div class="page page1"
          :class="{on: screen === 1}">
          <div class="nav">
            <div class="in">
              <div class="tline h5"
              @click="showmenu = true">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <img class="img" src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/c.png" />
              <div class="glass h5"
                :class="{on: showmenu}"
                @click="showmenu = false">
                <img :src="back"
                  :class="{hover: backhover}"
                  @touchstart="backhover = true"
                  @touchend="backhover = false"/>
              </div>
              <div class="list"
                :class="{on: showmenu}">
                <img class="img h5"
                  src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/c.png" />
                <div class="btn"
                  :class="{on: h5 ? screen == 1 : top < 1340}"
                  @click="toscreendir(1)">
                  <img class="h5" :src="n1" />
                  EcoTrac
                </div>
                <!-- <div class="btn"
                  :class="{on: h5 ? screen == 2 : (top >= 1340 && top < 4580)}"
                  @click="toscreendir(2)">
                  <img class="h5" :src="n2" />
                  产品故事
                </div> -->
                <div class="btn"
                  :class="{on: h5 ? screen == 5 : (top >= 4580 && top < 6740)}"
                  @click="toscreendir(4)">
                  <img class="h5" :src="n3" />
                  EcoTrac案例
                </div>
                <div class="btn"
                  :class="{on: h5 ? screen == 7 : top >= 6740}"
                  @click="toscreendir(6)">
                  <img class="h5" :src="n4" />
                  联系我们
                </div>
                <div class="btn"
                  @click="() => {topage('http://www.maxim-group.cn')}">
                  <img class="h5" :src="n5" />
                  返回美声官网
                </div>
                <div class="btn"
                  @click="() => {topage('https://ecotrac.cn/Business/#/login')}">
                  <img class="h5" :src="n6" />
                  登入EcoTrac
                </div>
              </div>
            </div>
          </div>
          <div class="center">
            <div class="btns">
              <div class="lbtn"
                @click="toban(-1)">
                <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/ga1.png" />
              </div>
              <div class="rbtn"
                @click="toban(1)">
                <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/ga1.png" />
              </div>
            </div>
            <div class="bannerout">
              <div class="txt">
                <img class="tx" src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/lo.png" />
                EcoTrac 易可追 产品全链透明追溯服务平台，包含了 PC 端、手机端、设备端、标签载体等多维度智能产品，为客户定制方案，提供品质服务。
                有防伪验真、溯源追踪、消费者互动、碳足迹管理、可持续发展、供应链透明等多样化的功能性产品，能同时服务品牌方、生产供应链端、零售商和消费者。
                <div class="btn"
                  @click="todown">
                  免费试用APP
                </div>
              </div>
              <div class="bannerin"
                :style="{
                  marginLeft: `${(ban * -100)}vw`
                }">
                <div class="item">
                  <!-- <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/bg1.png" /> -->
                  <img :src="main1" />
                  <!-- <div class="txt">
                    EcoTrac 易可追 产品全链透明追溯服务平台，包含了 PC 端、手机端、设备端、标签载体等多维度智能产品，为客户定制方案，提供品质服务。
                    有防伪验真、溯源追踪、消费者互动、碳足迹管理、可持续发展、供应链透明等多样化的功能性产品，能同时服务品牌方、生产供应链端、零售商和消费者。
                    <div class="btn"
                      @click="todown">
                      免费试用APP
                    </div>
                  </div> -->
                </div>
                <div class="item">
                  <!-- <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/bg2.png" /> -->
                  <img :src="main2" />
                  <!-- <div class="txt">
                    EcoTrac 易可追 产品全链透明追溯服务平台，包含了 PC 端、手机端、设备端、标签载体等多维度智能产品，为客户定制方案，提供品质服务。
                    有防伪验真、溯源追踪、消费者互动、碳足迹管理、可持续发展、供应链透明等多样化的功能性产品，能同时服务品牌方、生产供应链端、零售商和消费者。
                    <div class="btn"
                      @click="todown">
                      免费试用APP
                    </div>
                  </div> -->
                </div>
                <div class="item">
                  <!-- <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/bg3.png" /> -->
                  <img :src="main3" />
                  <!-- <div class="txt">
                    EcoTrac 易可追 产品全链透明追溯服务平台，包含了 PC 端、手机端、设备端、标签载体等多维度智能产品，为客户定制方案，提供品质服务。
                    有防伪验真、溯源追踪、消费者互动、碳足迹管理、可持续发展、供应链透明等多样化的功能性产品，能同时服务品牌方、生产供应链端、零售商和消费者。
                    <div class="btn"
                      @click="todown">
                      免费试用APP
                    </div>
                  </div> -->
                </div>
                <!-- <div class="item">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g2.png" />
                  <div class="txt">
                    EcoTrac 易可追 产品全链透明追溯服务平台，包含了 PC 端、手机端、设备端、标签载体等多维度智能产品，为客户定制方案，提供品质服务。
                    有防伪验真、溯源追踪、消费者互动、碳足迹管理、可持续发展、供应链透明等多样化的功能性产品，能同时服务品牌方、生产供应链端、零售商和消费者。
                    <div class="btn"
                      @click="todown">
                      免费试用APP
                    </div>
                  </div>
                </div>
                <div class="item">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g2.png" />
                  <div class="txt">
                    EcoTrac 易可追 产品全链透明追溯服务平台，包含了 PC 端、手机端、设备端、标签载体等多维度智能产品，为客户定制方案，提供品质服务。
                    有防伪验真、溯源追踪、消费者互动、碳足迹管理、可持续发展、供应链透明等多样化的功能性产品，能同时服务品牌方、生产供应链端、零售商和消费者。
                    <div class="btn"
                      @click="todown">
                      免费试用APP
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="bottom">
            <div class="item">
              <div class="data">
                -<div class="outbox">
                  <div class="inbox"
                    :class="oncls">
                    <div v-for="(i, k) in '*'.repeat(4).split('')"
                      :key="k">
                      {{k}}
                    </div>
                  </div>
                </div>%
              </div>
              <div class="desc">
                助力企业每年减少<br/>3%的碳排
                <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w18.png" />
              </div>
            </div>
            <div class="item">
              <div class="data">
                +<div class="outbox">
                  <div class="inbox"
                    :class="oncls">
                    <div v-for="(i, k) in '*'.repeat(11).split('')"
                      :key="k">
                      {{k}}
                    </div>
                  </div>
                </div>%
              </div>
              <div class="desc">
                助力企业每年提升<br/>10%的生产效益
                <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w18.png" />
              </div>
            </div>
            <div class="item">
              <div class="data">
                <div class="outbox">
                  <div class="inbox"
                    :class="oncls">
                    <div v-for="(i, k) in '*'.repeat(81).split('')"
                      :key="k">
                      {{k}}
                    </div>
                  </div>
                </div>%
              </div>
              <div class="desc">
                助力企业实现80%<br/>数字化转型
                <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w18.png" />
              </div>
            </div>
            <div class="item">
              <div class="data">
                <div class="outbox">
                  <div class="inbox"
                    :class="oncls">
                    <div v-for="(i, k) in '*'.repeat(101).split('')"
                      :key="k">
                      {{k}}
                    </div>
                  </div>
                </div>%
              </div>
              <div class="desc">
                2050 年 100%实现双碳<br/>目标,绿色零碳节能
                <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w18.png" />
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="page page2"
          :class="{on: screen === 2}">
          <div class="in">
            <div class="title">
              产品故事
            </div>
            <div class="imglistout">
            <div class="imglist">
              <div class="item"
                :class="{on: tag === 0}"
                @touchstart="tag = 0"
                @mouseenter="tag = 0">
                <div class="imgblock">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/t1.png" />
                </div>
                <div class="tag">
                  防伪验真
                </div>
              </div>
              <div class="item"
                :class="{on: tag === 1}"
                @touchstart="tag = 1"
                @mouseenter="tag = 1">
                <div class="imgblock">
                <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/t2.png" />
                </div>
                <div class="tag">
                  溯源追踪
                </div>
              </div>
              <div class="item"
                :class="{on: tag === 2}"
                @touchstart="tag = 2"
                @mouseenter="tag = 2">
                <div class="imgblock">
                <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/t3.png" />
                </div>
                <div class="tag">
                  供应链透明
                </div>
              </div>
              <div class="item"
                :class="{on: tag === 3}"
                @touchstart="tag = 3"
                @mouseenter="tag = 3">
                <div class="imgblock">
                <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/p4.png" />
                </div>
                <div class="tag">
                  碳足迹管理
                </div>
              </div>
              <div class="item"
                :class="{on: tag === 4}"
                @touchstart="tag = 4"
                @mouseenter="tag = 4">
                <div class="imgblock">
                <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/t5.png" />
                </div>
                <div class="tag">
                  可持续发展
                </div>
              </div>
              <div class="item"
                :class="{on: tag === 5}"
                @touchstart="tag = 5"
                @mouseenter="tag = 5">
                <div class="imgblock">
                <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/t6.png" />
                </div>
                <div class="tag">
                  消费者互动
                </div>
              </div>
            </div>
            </div>
            <div class="grays">
              <div class="gray"
                :class="{on: tag === 0 || tag === ''}">
                <div class="tip">防伪验真： </div>
                <div class="desc">EcoTrac 为产品提供一物一码，并运用美声开发的数据加密及解密模组 Secla™为不同等级加密需求的产品，提供数据保障的安全性，实现保护品牌和消费者权益的目标。</div>
              </div>
              <div class="gray"
              :class="{on: tag === 1}">
                <div class="tip">溯源追踪： </div>
                <div class="desc">从原材料、生产制造、品保质检、仓储物流等多个环节，做到全链路的产品生命周期管理，让产品的每一个环节都透明可追溯，更可靠可信。</div>
              </div>
              <div class="gray"
              :class="{on: tag === 2}">
                <div class="tip">供应链透明： </div>
                <div class="desc">EcoTrac 提供企业或品牌方对上中下游供应商进行产程追踪、在线库存、产品数据、订单信息等，使产品全程供应链透明化，可视可查。并且可依需求提供定制化服务，为不同产业链配置定制化解决方案。</div>
              </div>
              <div class="gray"
              :class="{on: tag === 3}">
                <div class="tip">碳足迹管理： </div>
                <div class="desc">EcoTrac 提供可视化碳排放计算系统，可以在产品生产过程中进能源管理及碳排计算，助力企业达到节能减排，逐步实现碳中和目标。</div>
              </div>
              <div class="gray"
              :class="{on: tag === 4}">
                <div class="tip">可持续发展： </div>
                <div class="desc">EcoTrac 系列产品可从多维度协助企业搭建环境、社会和公司治理的（ESG）产业循环框架，促进企业经济可持续发展。</div>
              </div>
              <div class="gray"
              :class="{on: tag === 5}">
                <div class="tip">消费者互动： </div>
                <div class="desc">EcoTrac 为品牌打造消费者互动和营销方案，可协助品牌实现品宣推广、用户消费分析、获取直客数据，提高复购率、培养消费者黏性等。</div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="page page3"
          :class="{on: screen === 2}">
          <div class="in">
            <div class="imgslide">
              <img :src="cg1" />
              <img :src="cg2" />
              <img :src="cg3" />
            </div>
          </div>
        </div>
        <div class="page page4"
          :class="{on: screen === 3}">
          <div class="in">
            <div class="video">
              <div class="mask" v-if="visi">
                <img class="pl" src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/r1.png"
                  @click="play()" />
                <div class="ani"
                   @click="play()" >
                  <div class="aniout1">
                    <div class="cirout">
                      <div class="cir">
                      </div>
                    </div>
                  </div>
                  <div class="aniout2">
                    <div class="cirout">
                      <div class="cir">
                      </div>
                    </div>
                  </div>
                </div>
                <img class="videobg"
                  src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/bg.png" />
              </div>
              <video ref="v" controls class="m1" src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/a.mp4"/>
              <!-- <img class="m1" src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/bg.png" /> -->
            </div>
          </div>
        </div>
        <div class="page page5"
          :class="{on: screen === 4}">
          <div class="in">
            <div class="tabs">
              <div class="tab"
                @click="tab = 0"
                :class="{on: tab === 0}">防伪验真</div>
              <div class="tab"
                @click="tab = 1"
                :class="{on: tab === 1}">产品溯源及碳足迹</div>
              <div class="tab"
                @click="tab = 2"
                :class="{on: tab === 2}">消费者互动</div>
              <div class="tab"
                @click="tab = 3"
                :class="{on: tab === 3}">智能包装应用</div>
            </div>
            <div class="contents">
              <div class="content"
                :class="{on: tab === 0}">
                <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/gt3.png" />
                <div class="b">
                  <div class="l">
                    <div class="mask" v-if="visi1">
                      <img class="pl" src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/r1.png"
                        @click="play(1)"/>
                      <div class="ani"
                         @click="play(1)" >
                        <div class="aniout1">
                          <div class="cirout">
                            <div class="cir">
                            </div>
                          </div>
                        </div>
                        <div class="aniout2">
                          <div class="cirout">
                            <div class="cir">
                            </div>
                          </div>
                        </div>
                      </div>
                      <img class="videobg" :src="vm2" />
                    </div>
                    <video ref="v1" controls class="m1" src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/v1.mp4"/>
                  </div>
                  <div class="r"
                        @mouseenter="scrolllock"
                        @mouseleave="scrollunlock">
                    <div class="ri"
                      @mouseleave.stop>
                      <div class="gre">
                        防伪验真
                      </div>
                      <div class="txt">
                        经过 EcoTrac 系统数据加密的产品，可以通过 EcoTrac APP 进行防伪验真。为品牌提供更好的防伪验真服务，为消费者提供正品保障。<br />
                        某品牌运动鞋因市场上流动的大量仿冒品而感到困扰。EcoTrac 为其设计了一物一码标签防伪方案，搭配营销和定制包装回收的零售管理服务。消费者可通过 APP 感应标签中的芯片来激活一物一码的防伪码，达到首扫和验真的效果。品牌还为其运动鞋舌标定制了加密图像款热转印图案，通过APP 扫描识别，即可进行 WebAR 实现试鞋互动应用。
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content"
                :class="{on: tab === 1}">
                <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g.png" />
                <div class="b">
                  <div class="l">
                   <div class="mask" v-if="visi2">
                      <img class="pl" src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/r1.png"
                        @click="play(2)"/>
                      <div class="ani"
                         @click="play(2)" >
                        <div class="aniout1">
                          <div class="cirout">
                            <div class="cir">
                            </div>
                          </div>
                        </div>
                        <div class="aniout2">
                          <div class="cirout">
                            <div class="cir">
                            </div>
                          </div>
                        </div>
                      </div>
                      <img class="videobg" :src="vm1" />
                    </div>
                    <video ref="v2" controls class="m1" src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/v2.mp4"/>
                  </div>
                  <div class="r"
                        @mouseenter="scrolllock"
                        @mouseleave="scrollunlock">
                    <div class="ri">
                      <div class="gre">
                        产程追溯及碳足迹
                      </div>
                      <div class="txt">
                        EcoTrac 可通过系统进行从原料到供应链的全产程记录及每个环节可被追踪，提供更安全可靠的产品，并在产程中记录每个环节的耗能，计算出碳排，帮助企业实现产品碳足迹及碳中和目标。<br/>
                        德国品牌汽船是海外定制版，需要让产品为消费者提供可视化的产程追溯、质量认证，以此提高消费者体验，使消费者对产品有高度的品质和安全信任，能够放心购买使用。汽船采用复合式NFC 芯片热转印标签烫印在船体上，标签具有防水防伪防剥离功效，当消费者在户外时，仅需使用智能手机轻触汽船上的标签即可看到使用说明书、产品信息、官网或购物商城、客服联系方式等，也可引导消费者快捷复购配件等，是品牌信赖的优质互动应用方案。
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content"
                :class="{on: tab === 2}">
                <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/gt2.png" />
                <div class="b">
                  <div class="l">
                    <div class="mask" v-if="visi3">
                      <img class="pl" src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/r1.png"
                        @click="play(3)"/>
                      <div class="ani"
                         @click="play(3)" >
                        <div class="aniout1">
                          <div class="cirout">
                            <div class="cir">
                            </div>
                          </div>
                        </div>
                        <div class="aniout2">
                          <div class="cirout">
                            <div class="cir">
                            </div>
                          </div>
                        </div>
                      </div>
                      <img class="videobg" :src="vm3" />
                    </div>
                    <video ref="v3" controls class="m1" src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/v3.mp4"/>
                  </div>
                  <div class="r"
                      @mouseenter="scrolllock"
                      @mouseleave="scrollunlock">
                    <div class="ri">
                      <div class="gre">
                        消费者互动
                      </div>
                      <div class="txt">
                        在产品上使用智能标签，消费者可通过轻触电子标签上的智能芯片或扫一扫二维码，进行防伪验真后获取产品信息、复购引流或进入消费者互动页面等，培养产品黏性。<br/>
                        Milo 智能宠物互动限量版 T 恤礼盒，采用多种类智能标签的综合应用。Milo 礼盒包装上印有下载Ecotrac APP 的二维码，消费者开箱前可扫码下载 App，通过 App 验真识别包装上和 T 恤上的加密图像，确认产品真伪。同时也能使用 Milo 一物一码激活 卡进行产品验真，确保此款产品为首次开箱。<br/>
                        Milo T 恤可通过 EcoTrac APP 扫衣服上印有图像加密热转印的卡通狗 Milo 图案，或用手机碰触NFC 热转印标签，即可进入品牌开发的互动游戏页面、商城引流复购，达到品牌营销互动并提高复购率。
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content"
                :class="{on: tab === 3}">
                <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/gt1.png" />
                <div class="b">
                  <div class="l">
                    <div class="mask" v-if="visi4">
                      <img class="pl" src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/r1.png"
                        @click="play(4)"/>
                      <div class="ani"
                         @click="play(4)" >
                        <div class="aniout1">
                          <div class="cirout">
                            <div class="cir">
                            </div>
                          </div>
                        </div>
                        <div class="aniout2">
                          <div class="cirout">
                            <div class="cir">
                            </div>
                          </div>
                        </div>
                      </div>
                      <img class="videobg" :src="vm4" />
                    </div>
                    <video ref="v4" controls class="m1" src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/v4.mp4"/>
                  </div>
                  <div class="r"
                      @mouseenter="scrolllock"
                      @mouseleave="scrollunlock">
                    <div class="ri">
                      <div class="gre">
                        智能包装应用
                      </div>
                      <div class="txt">
                        智能包装可以应用 EcoTrac 系统的数字化功能，为品牌提高产品价值，形态上更为环保。<br/>
                        某医药包装采用了 EcoTrac 智能包装产品，在其包装盒中复合了防伪互动 NFC 芯片，用户轻触标签后会引导下载验真 App，打开 App 后 再触碰包装标签後，可查看电子说明书、用户注册登录、查询溯源记录、查询用药记录、快捷复购、访问官网、拨打客服电话等互动功能。将普通的包装迭代至智能包装，为其赋予更多价值。
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="page page6"
          :class="{on: screen === 5}">
          <div class="in">
            <div class="title">
              绿色认证
            </div>
            <div class="picwall">
              <div class="line"
                :style="{
                  transform: `translate(${trans[0]}px, 0`,
                }">
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g6.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w5.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g7.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w19.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g6.png" />
                  <img class="g41" :src="g41" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g7.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w6.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g6.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w5.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g7.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w19.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g6.png" />
                  <img class="g41" :src="g41" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g7.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w6.png" />
                </div>
              </div>
              <div class="line"
                :style="{
                  transform: `translate(${trans[1]}px, 0`,
                }">
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g8.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w8.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g9.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w7.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g8.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w14.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g9.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w1.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g8.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w8.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g9.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w7.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g8.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w14.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g9.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w1.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g12.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w12.png" />
                </div>
              </div>
              <!-- <div class="line"
                :style="{
                  transform: `translate(${trans[2]}px, 0`,
                }">
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g10.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w15.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g11.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w19.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g10.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w4.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g11.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w11.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g10.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w15.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g11.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w19.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g10.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w4.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g11.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w11.png" />
                </div>
              </div>
              <div class="line"
                :style="{
                  transform: `translate(${trans[3]}px, 0`,
                }">
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g12.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w12.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g13.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w22.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g12.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g5.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g13.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w21.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g12.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w12.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g13.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w22.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g12.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g5.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g13.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w21.png" />
                </div>
                <div class="rec">
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g12.png" />
                  <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/w12.png" />
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="page page7"
          :class="{on: screen === 6 || screen === 7}">
          <div class="in">
            <div class="title">
              联系我们
            </div>
            <div class="info">
              <div class="top">
                  <img class="pc" src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/con.png" />
                  <img class="h5" :src="bb" />
                <div class="txt">
                  <div class="h5 tttt">关于我们</div>
                  <span class="pc">关于我们：<br/></span>
                  EcoTrac 易可追是美声集团研发的全链透明追溯服务平台，可以根据客户的需求提供数字化解决方案和技术支持，协助客户向上管理产品全生命周期，向下培养消费者粘性，打造可持续发展的循环产业链条。
                </div>
              </div>
              <div class="con">
                <div class="">
                  商务合作：+86 18717987935
                </div>
              </div>
              <div class="line">
                <div class="l1"><span><span>联系电话</span>：</span>+86 21 6406 4227 ext.863</div>
                <!-- <div class="l1">商务合作：+86 18717987935</div> -->
                <div class="l1"><span><span>商务微信ID</span>：</span>maximgroup</div>
                <div class="l1"><span><span>地址</span>：</span>中国 上海市闵行区合川路 3142 弄 49 号</div>
                <div class="l1">
                  <span><span>邮箱</span>：</span><a class="mail" href="mailto:admin.ecotrac@maxim-group.com">
                  admin.ecotrac@maxim-group.com
                  </a>
                </div>
                <div class="l1"><span><span>联系时间</span>：</span>10:00-12:00 13:00-17:00 周一～周五（节假日除外）</div>
                <div class="l1">
                  <span><span>社交媒体</span>：</span>
                  <a class="link"
                    href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkxNTE0NDQ4MQ==#wechat_redirect"
                    target="_blank">
                    <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/dd1.png" />
                    <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/d1.png" />
                  </a>
                  <a class="link"
                    href="https://www.linkedin.com/company/maximlabel"
                    target="_blank">
                    <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/dd4.png" />
                    <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/d4.png" />
                  </a>
                  <a class="link" href="https://www.facebook.com/maximlabelandpackaging/"
                    target="_blank">
                    <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/dd2.png" />
                    <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/d2.png" />
                  </a>
                  <a class="link" href="https://twitter.com/MaximLabel"
                    target="_blank">
                    <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/dd5.png" />
                    <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/d5.png" />
                  </a>
                  <a class="link"
                    href="https://www.youtube.com/channel/UCn9zkSp68qS0ZSr95vbkUCg"
                    target="_blank">
                    <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/dd6.png" />
                    <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/d6.png" />
                  </a>
                  <a class="link"
                    href="https://www.instagram.com/accounts/login/?next=/maximgroup_official/"
                    target="_blank">
                    <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/dd3.png" />
                    <img src="https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/d3.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="bnav">
            Copyright © 2022 Maxim. All Rights Reserved .<br class="h5" />
            浙江美声智能系统有限公司
            <a href="https://beian.miit.gov.cn/" target="_blank">
              浙ICP备19000159号-2
            </a>
            <a href="https://ecotrac.cn/documents/privacy.html" target="_blank">
            《隐私政策》
            </a>
            <a href="https://ecotrac.cn/documents/support.html" target="_blank">
            《用户协议》
            </a>
          </div>
        </div>
      </div>
      <!-- <div class="ttg">
        浙江美声-EcoTrac
      </div> -->
  </div>
</template>

<script>
const a1 = 'https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/a1.png'
const down = 'https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/down.jpeg'
const contact = 'https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/contact.png'
const main1 = 'https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/bg1.png'
const main2 = 'https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/bg2.png'
const main3 = 'https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/bg3.png'
const vm1 = 'https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/vm1.png'
const vm2 = 'https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/vm2.png'
const vm3 = 'https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/vm3.png'
const vm4 = 'https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/vm4.png'
const cg1 = 'https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/cg1.png'
const cg2 = 'https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/cg2.png'
const cg3 = 'https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/cg3.png'
const bb = 'https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/bb.png'
const n1 = 'https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/n1.png'
const n2 = 'https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/n2.png'
const n3 = 'https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/n3.png'
const n4 = 'https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/n4.png'
const n5 = 'https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/n5.png'
const n6 = 'https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/n6.png'
const back = 'https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/back.png'
const g41 = 'https://ecotrac.oss-cn-hangzhou.aliyuncs.com/MainPage/g41.png'

export default {
  name: 'Home',
  data () {
    return {
      backhover: false,
      g41,
      bb,
      vm1,
      vm2,
      vm3,
      vm4,
      cg1,
      cg2,
      cg3,
      a1,
      n1,
      n2,
      n3,
      n4,
      n5,
      n6,
      back,
      tab: 0,
      top: 0,
      tag: '',
      ban: 0,
      hover: 0,
      inters: [],
      trans: [],
      lock: [],
      oncls: '',
      visi: true,
      pageon: [],
      wheeldis: 0,
      wheellock: false,
      page: 1,
      screen: 0,
      slock: false,
      visi1: true,
      visi2: true,
      visi3: true,
      visi4: true,
      down,
      contact,
      main1,
      main2,
      main3,
      showmenu: false,
      h5: false
    }
  },
  components: {
  },
  mounted () {
    if (window.innerWidth > 600) {
      window.addEventListener('wheel', this.scroll)
    } else {
      window.addEventListener('scroll', this.scroll)
    }
    window.addEventListener('keypress', this.keypress)
    window.addEventListener('resize', this.checkh5)
    this.checkh5()
    this.setall()
    this.$refs.v.addEventListener('pause', () => {
      this.visi = true
    })
    if (window.innerWidth > 600) {
      setTimeout(() => {
        this.screen = 1
      }, 300)
    } else {
      setTimeout(() => {
        this.screen = 1
      }, 300)
    }
  },
  beforeDestroy () {
    if (window.innerWidth > 600) {
      window.removeEventListener('wheel', this.scroll)
    } else {
      window.removeEventListener('scroll', this.scroll)
    }
    window.removeEventListener('keypress', this.keypress)
    window.removeEventListener('resize', this.checkh5)
    this.clearall()
  },
  methods: {
    checkh5 () {
      if (window.innerWidth < 600) {
        this.h5 = true
      } else {
        this.h5 = false
      }
    },
    touchstart (e) {
      console.log(e)
    },
    keypress (event) {
      console.log(event)
      if (event.code.toLowerCase() === 'space') {
        if (event.shiftKey) {
          this.toscreen(-1)
        } else {
          this.toscreen(1)
        }
      }
    },
    tous () {
      this.toscreendir(6)
    },
    getTrans () {
      if (window.innerWidth < 600) {
        return ''
      }
      if (this.screen < 7) {
        return `translate(0, calc(-100vh * ${this.screen - 1}))`
      } else {
        return 'translate(0, calc(-100vh * 5 - 50px))'
      }
    },
    scrolllock () {
      this.slock = true
    },
    scrollunlock () {
      this.slock = false
    },
    tomail () {
    },
    topage (str) {
      window.location.href = str
    },
    play (str) {
      if (!str) {
        this.$refs.v.play()
        this.visi = false
      } else {
        this.visi1 = true
        this.visi2 = true
        this.visi3 = true
        this.visi4 = true
        this[`visi${str}`] = false
        this.$refs.v1.pause()
        this.$refs.v2.pause()
        this.$refs.v3.pause()
        this.$refs.v4.pause()
        this.$refs[`v${str}`].play()
      }
    },
    clearall () {
      this.inters.forEach(v => {
        clearInterval(v)
      })
    },
    setall () {
      Array.from(document.querySelectorAll('.picwall .line')).forEach((v, k) => {
        v.addEventListener('mouseenter', () => {
          this.lock[k] = true
        })
        v.addEventListener('mouseleave', () => {
          this.lock[k] = false
        })
        this.inters.push(setInterval(() => {
          if (!this.lock[k]) {
            if (window.innerWidth <= 1440) {
              if (k % 2 === 0) {
                this.trans[k] = (this.trans[k] || 0) - 1
                if (this.trans[k] <= -864) {
                  this.trans[k] = 0
                }
              } else {
                this.trans[k] = (this.trans[k] || 0) + 1
                if (this.trans[k] >= 864) {
                  this.trans[k] = 0
                }
              }
            } else {
              if (k % 2 === 0) {
                this.trans[k] = (this.trans[k] || 0) - 1
                if (this.trans[k] <= -1280) {
                  this.trans[k] = 0
                }
              } else {
                this.trans[k] = (this.trans[k] || 0) + 1
                if (this.trans[k] >= 1280) {
                  this.trans[k] = 0
                }
              }
            }
            this.$forceUpdate()
          }
        }, 30))
      })
    },
    scroll (e) {
      if (window.innerWidth < 600) {
        console.log(e)
        const t = document.children[0].scrollTop
        console.log(t)
        if (t < 758) {
          this.screen = 1
        } else if (t >= 758 && t < 1341) {
          this.screen = 5
        } else {
          this.screen = 7
        }
        //  if (i === 1) {
        //   document.children[0].scrollTo(0, 0)
        // } else if (i === 2) {
        //   document.children[0].scrollTo(0, 300)
        // } else if (i === 5) {
        //   document.children[0].scrollTo(0, 1044)
        // } else if (i === 7) {
        //   document.children[0].scrollTo(0, 1622)
        // }
        return
      }
      if (this.wheellock) {
        return
      }
      if (this.slock) {
        return
      }
      this.wheeldis = this.wheeldis + e.deltaY
      if (this.wheeldis > 100) {
        this.wheellock = true
        this.wheeldis = 0
        this.toscreen(1)
        setTimeout(() => {
          this.wheellock = false
        }, 1000)
      } else if (this.wheeldis < -100) {
        this.wheellock = true
        this.wheeldis = 0
        this.toscreen(-1)
        setTimeout(() => {
          this.wheellock = false
        }, 1000)
      }
    },
    toscreendir (i) {
      this.screen = i
      if (window.innerWidth < 600) {
        if (i === 1) {
          document.children[0].scrollTo(0, 0)
        } else if (i === 4) {
          document.children[0].scrollTo(0, 758)
        } else if (i === 6) {
          document.children[0].scrollTo(0, 1341)
        }
        this.showmenu = false
      }
    },
    toscreen (i) {
      this.screen = this.screen + i
      if (this.screen <= 1) {
        this.screen = 1
      } else if (this.screen > 7) {
        this.screen = 7
      }
    },
    scrollTo2 (target, num) {
      if (this.pageon.indexOf(num) === -1) {
        this.pageon.push(num)
      }
      this.scrollTo(target)
    },
    scrollTo (target = 0) {
      let cur = this.top
      const step = (target - this.top) / 100
      const inter = setInterval(() => {
        cur += step
        window.scrollTo(0, cur)
        if (Math.abs(cur - target) < 1) {
          clearInterval(inter)
        }
      }, 3)
    },
    totop () {
      // this.scrollTo()
      this.toscreendir(1)
    },
    toban (i) {
      this.ban = this.ban + i
      if (this.ban < 0) {
        this.ban = 2
      }
      if (this.ban > 2) {
        this.ban = 0
      }
    },
    todown () {
      window.location.href = 'https://ecotrac.cn/download/'
    }
  }
}
</script>

<style scoped lang='scss'>
.home {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .pc {
    display: block !important;
  }
  .h5 {
    display: none !important;
  }
  .a1 {
    position: absolute;
    z-index: 99;
    height: 12px;
    left: 50%;
    bottom: 15px;
    transform: translate(-50%, 0);
    cursor: pointer;
    img {
      display: block;
      height: 12px;
    }
  }
  .nav {
    height: 60px;
    background: #000000;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    > .in{
      flex: none;
    }
    .img {
      width: 121px;
      height: 26px;
      display: block;
    }
    .list {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1;
      .btn {
        height: 60px;
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
        box-sizing: border-box;
        line-height: 57px;
        border-bottom: 3px solid transparent;
        margin: 0 18px;
        opacity: 0.6;
        cursor: pointer;
        transform: all 0.3s ease;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          z-index: 1;
          bottom: -3px;
          height: 3px;
          left: 50%;
          transform: translate(-50%, 0) scale(0, 1);
          transform-origin: 50% 0;
          transition: all 0.3s ease;
          width: 100%;
          background: #fff;
        }
        &.on,
        &:hover {
          // border-bottom-color: #FFFFFF;
          opacity: 1;
          &:after {
            transform: translate(-50%, 0) scale(1, 1);
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .in {
    margin: 0 auto;
    width: 1080px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  .pages {
    transition: all 0.3s ease;
  }
  .side {
    position: fixed;
    z-index: 99;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    .btn {
      width: 64px;
      height: 64px;
      background: #9EC222;
      border-radius: 0px 0px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1px;
      position: relative;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        background: #2e4828;
        img {
          // transition: all 0.3s ease;
          // transform: rotateY(360deg);
        }
      }
      img {
        display: block;
        height: 31px;
      }
      &.up {
        img {
          height: 31px;
        }
      }
      &.mail {
        img {
          height: 31px;
        }
      }
      &.cs {
        img {
          height: 36px;
        }
      }
      &.app {
        img {
          height: 34px;
        }
      }
      &.pcicon {
        img {
          height: 32px;
        }
      }
      .pop {
        position: absolute;
        right: 65px;
        top: 0;
        // transform: translate(0, -50%);
        z-index: -1;
        display: none;
        // display: none;
        .code {
          width: 154px;
          height: 194px;
          background: #2E4828;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            display: block;
            width: 124px;
            height: 124px;
          }
          .codetip {
            font-size: 18px;
            color: #FFFFFF;
            line-height: 19px;
            margin-top: 13px;
          }
        }
        // img {
        //   height: 120px;
        //   display: block;
        //   // transform: translate(100%, 0);
        //   // opacity: 0;
        //   // transition: all 0.3s ease;
        // }
        span {
          height: 64px;
          width: 148px;
          background: rgba(46, 72, 40, 1);
          line-height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          // transform: translate(100%, 0);
          // opacity: 0;
          // transition: all 0.3s ease;
          // padding: 0 20px;
          // border-radius: 3px;
          white-space: nowrap;
          color: #fff;
        }
      }
      &:hover {
        .pop {
          display: block;
          // img,
          // span {
          //   transform: translate(0%, 0);
          //   opacity: 1;
          // }
        }
      }
    }
  }
  .page {
    min-width: 1200px;
    min-height: 600px;
    box-sizing: border-box;
    // padding-top: 60px;
  }
  .page1 {
    opacity: 0;
    // transform: translate(-100px, 0);
    transition: all 0.3s ease;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &.on {
      opacity: 1;
      // transform: translate(0, 0);
      .outbox {
        line-height: 90px;
        height: 90px;
        overflow: hidden;
        .inbox {
          height: auto;
          transition: all 2s ease 1s;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          transform: translate(0, calc(-100% + 90px));
        }
      }
    }
    .center {
      position: relative;
      z-index: 1;
      flex: 1;
      overflow: hidden;
      .lbtn,
      .rbtn {
        width: 58px;
        height: 111px;
        background: rgba(255,255,255,0.1);
        border-radius: 0px 0px 0px 0px;
        position: absolute;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        >img {
          width: 58px;
          height: 111px;
        }
      }
      .lbtn {
        left: 0;
      }
      .rbtn {
        right: 0;
        transform: rotate(180deg);
      }
      .btns {
        width: 1080px;
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .bannerout {
        width: 100vw;
        height: 100%;
        overflow: hidden;
        .txt {
          position: absolute;
          z-index: 2;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          top: 50%;
          left: 50%;
          width: 800px;
          transform: translate(-50%, -50%);
          font-size: 18px;
          line-height: 32px;
          color: #fff;
          text-align: left;
          margin-top: 60px;
          .tx {
            width: 205px;
            height: 43px;
            margin-block: 26px;
          }
          .btn {
            background: #fff;
            border-radius: 40px;
            color: #9EC222;
            font-size: 18px;
            line-height: 40px;
            padding: 0 30px;
            cursor: pointer;
            margin-top: 20px;
          }
        }
        .bannerin {
          width: 500vw;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          transition: all 0.3s ease;
          .item {
            width: 100vw;
            height: 100%;
            position: relative;
            &:before {
              content: '';
              display: block;
              position: absolute;
              z-index: 1;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0,0,0,.4);
            }
            .txt {
              position: absolute;
              z-index: 2;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              top: 50%;
              left: 50%;
              width: 800px;
              transform: translate(-50%, -50%);
              font-size: 18px;
              line-height: 32px;
              color: #fff;
              text-align: left;
              margin-top: 60px;
              .btn {
                background: #fff;
                border-radius: 40px;
                color: #9EC222;
                font-size: 18px;
                line-height: 40px;
                padding: 0 30px;
                cursor: pointer;
                margin-top: 20px;
              }
            }
            > img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .bottom {
      height: 170px;
      background: #404040;
      display: flex;
      align-items: center;
      justify-content: center;
      .item {
        margin: 0 40px;
        .data {
          font-size: 60px;
          font-weight: 500;
          color: #FFFFFF;
          margin-bottom: -20px;
          display: flex;
          align-items: center;
          justify-content: center;
          .outbox {
            line-height: 90px;
            height: 90px;
            overflow: hidden;
            .inbox {
              height: auto;
              transition: all 2s ease 1s;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              &.on {
                transform: translate(0, calc(-100% + 90px));
              }
            }
          }
        }
        .desc {
          position: relative;
          z-index: 1;
          font-size: 16px;
          font-weight: 500;
          color: #8E8E8E;
          line-height: 30px;
          width: 210px;
          height: 87px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0;
          > img {
            position: absolute;
            z-index: 1;
            left: 50%;
            top: 50%;
            width: 154px;
            height: 107px;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
  .title {
    font-size: 30px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #000000;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before,
    &:after {
      content: '';
      display: block;
      width: 76px;
      height: 2px;
      background: #000000;
    }
    &:before {
      margin-right: 27px;
    }
    &:after {
      margin-left: 27px;
    }
  }
  .page2 {
    height: 100vh;
    position: relative;
    z-index: 1;
    opacity: 0;
    // transform: translate(100px, 0);
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    &.on {
      opacity: 1;
      // transform: translate(0, 0);
    }
    .in {
      flex-direction: column;
      position: relative;
      z-index: 2;
      padding: 20px 0;
    }
    .title {
      // margin-top: 119px;
      // margin-bottom: 80px;
      flex: 1;
      max-height: 200px;
    }
    .imglist {
      width: 1080px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .item {
        width: calc(365px / 1.4);
        height: calc(255px / 1.4);
        position: relative;
        z-index: 1;
        margin-right: 60px;
        margin-bottom: 38px;
        cursor: pointer;
        overflow: hidden;
        transition: all 0.3s ease;
        &:before {
          content: '';
          display: block;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 2;
          background: rgba(171, 232, 130, 1);
          // opacity: 0.5;
          opacity: 0;
        }
        &.on,
        &:hover {
          img {
            // transform: scale(1);
            transform: scale(0.8);
          }
          .tag {
            opacity: 1;
          }
          .imgblock {
            // opacity: 1;
            opacity: 0.5;
          }
          &:before {
            // opacity: 0;
            opacity: 0.5;
          }
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
        .tag {
          position: absolute;
          z-index: 9;
          opacity: 0;
          font-size: 30px;
          color: #FFFFFF;
          padding: 8px 32px;
          background: rgba(158, 194, 34, 1);
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          white-space: nowrap;
          transition:  all 0.3s ease;
        }
        .imgblock {
          background: #9ec222;
          // opacity: 0.5;
          opacity: 1;
        }
        img {
          width: 100%;
          height: 100%;
          // transform: scale(0.8);
          transform: scale(1);
          transition: all 0.3s ease;
        }
      }
    }
    .grays {
      position: relative;
      z-index: 1;
      width: 100%;
      min-height: 102px;
      width: 900px;
      flex: 1;
    }
    .gray {
      width: 1185px;
      width: 900px;;
      height: 102px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: #F8F8F8;
      box-sizing: border-box;
      padding: 17px 13px;
      opacity: 0;
      transform: translate(0, -100%);
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      transition: all 0.5s ease;
      &.on {
        opacity: 1;
        transform: translate(0, 0);
        transition: all 0.5s ease 0.3s;
      }
      .tip {
        color: rgba(158, 194, 34, 1);
        font-size: 20px;
        text-align: left;
        width: 100%;
      }
      .desc {
        color: rgba(93, 93, 93, 1);
        font-size: 20px;
        text-align: left;
        width: 100%;
      }
    }
  }
  .page3 {
    height: 100vh;
    width: 100vw;
    background: #F0F6EE;
    opacity: 0;
    // transform: translate(-100px, 0);
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    &.on {
      opacity: 1;
      // transform: translate(0, 0);
    }
    .imgslide {
      position: relative;
      z-index: 1;
      width: calc(1000px / 1.2);
      height: calc(730px / 1.2);
      // margin: 173px auto 0 auto;
      img {
        display: block;
        position: absolute;
        z-index: 1;
        background: #fff;
        &:nth-child(1) {
          width: calc(1000px / 1.4);
          height: calc(730px / 1.4);
          left: 50%;
          top: 50%;
          z-index: 1;
          transform: translate(-50%, -50%);
          transform-origin: 50% 50%;
          animation: roll1 12s linear infinite;
        }
        &:nth-child(2) {
          width: calc(1000px / 1.4);
          height: calc(730px / 1.4);
          left: 50%;
          top: 50%;
          z-index: 2;
          transform: translate(-50%, -50%);
          transform-origin: 50% 50%;
          animation: roll2 12s linear infinite;
        }
        &:nth-child(3) {
          width: calc(1000px / 1.4);
          height: calc(730px / 1.4);
          left: 50%;
          top: 50%;
          z-index: 1;
          transform: translate(-50%, -50%);
          transform-origin: 50% 50%;
          animation: roll3 12s linear infinite;
        }
      }
    }
  }
  .page4 {
    height: 100vh;
    width: 100vw;
    opacity: 0;
    // transform: translate(100px, 0);
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    &.on {
      opacity: 1;
      // transform: translate(0, 0);
    }
    .video {
      width: 1080px;
      height: 560px;
      margin: 0 auto;
      position: relative;
      z-index: 1;
      // margin-top: 219px;
      .mask {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0,0,0,.07);
        cursor: pointer;
        &:before {
          position: absolute;
          z-index: 2;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,.2);
          content: '';
          display: block;
        }
        .pl {
          width: 56px;
          height: 65px;
          position: relative;
          z-index: 2;
        }
        .ani {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          z-index: 3;
          left: calc(50% - 10px);
          top: 50%;
          transform: translate(-50%, -50%);
          &:hover {
            .aniout1 {
              .cirout {
                animation: cir1 3s linear;
                animation-fill-mode: forwards;
              }
            }
            .aniout2 {
              .cirout {
                animation: cir2 3s linear;
                animation-fill-mode: forwards;
              }
            }
          }
        }
        .aniout1 {
          width: 50px;
          height: 100px;
          overflow: hidden;
          position: relative;
          .cirout {
            position: absolute;
            z-index: 1;
            height: 100px;
            width: 50px;
            overflow: hidden;
            top: 0;
            left: 0;
            transform-origin: 100% 50%;
            transform: rotate(-180deg);
            transition: all 0.3s ease;
            .cir {
              position: absolute;
              z-index: 1;
              width: 100px;
              height: 100px;
              border: 2px solid #99b712;
              border-radius: 120px;
              box-sizing: border-box;
              left: 0;
              top: 0;
            }
          }
        }
        .aniout2 {
          width: 50px;
          height: 100px;
          overflow: hidden;
          position: relative;
          .cirout {
            position: absolute;
            z-index: 1;
            height: 100px;
            width: 50px;
            overflow: hidden;
            top: 0;
            left: 0;
            transform-origin: 0 50%;
            transform: rotate(-180deg);
            transition: all 0.3s ease;
            .cir {
              position: absolute;
              z-index: 1;
              width: 100px;
              height: 100px;
              border: 2px solid #99b712;
              border-radius: 100px;
              box-sizing: border-box;
              right: 0;
              top: 0;
            }
          }
        }
        .videobg {
          filter: brightness(0.7);
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
        }
      }
      .m1 {
        height: 100%;
        width: 100%;
      }
      .m {
        width: 100%;
        height: 100%;
      }
    }
  }
  .page5 {
    height: 100vh;
    width: 100vw;
    background: #F0F5EF;
    opacity: 0;
    // transform: translate(-100px, 0);
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    &.on {
      opacity: 1;
      // transform: translate(0, 0);
    }
    .in {
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: space-evenly;
    }
    .tabs {
      // margin-top: 87px;
      // margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      width: 900px;
      border-bottom: 8px solid rgba(193, 193, 193, 1);
      .tab {
        font-size: 26px;
        font-weight: bold;
        color: rgba(158, 194, 34, 0.5);
        // padding: 0 23px;
        height: 70px;
        border-bottom: 8px solid transparent;
        box-sizing: border-box;
        line-height: 70px;
        // margin: 0 60px;
        margin-bottom: -8px;
        flex: 1;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover,
        &.on {
          border-bottom-color: rgba(0, 0, 0, 1);
          color: rgba(0, 0, 0, 1);
        }
        &:first-child {
          padding-left: 0;
          margin-left: 0;
          text-align: left;
        }
        &:last-child {
          padding-right: 0;
          margin-right: 0;
          text-align: right;
        }
      }
    }
    .contents {
      flex: 1;
      position: relative;
      width: 900px;
      height: calc(100% - 60px);
      // height: auto;
      overflow: hidden;
      height: 565px;
      flex: none;
    }
    .content {
      box-sizing: border-box;
        > img {
          width: 900px;
        }
      .b {
        padding: 28px;
        box-sizing: border-box;
        width: 100%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        overflow: hidden;
        height: 320px;
        flex: none;
        .l {
          width: 402px;
          height: 250px;
          margin-right: 20px;
          transition: all 1s ease 1.5s;
          transform: translate(0, 100%);
          opacity: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          position: relative;
          z-index: 1;
          // >img {
          //   height: 385px;
          // }
          .mask {
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(0,0,0,.07);
            cursor: pointer;
            .pl {
              width: 56px;
              height: 65px;
              position: relative;
              z-index: 2;
            }
            .ani {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              z-index: 3;
              left: calc(50% - 10px);
              top: 50%;
              transform: translate(-50%, -50%);
              &:hover {
                .aniout1 {
                  .cirout {
                    animation: cir1 3s linear;
                    animation-fill-mode: forwards;
                  }
                }
                .aniout2 {
                  .cirout {
                    animation: cir2 3s linear;
                    animation-fill-mode: forwards;
                  }
                }
              }
            }
            .aniout1 {
              width: 50px;
              height: 100px;
              overflow: hidden;
              position: relative;
              .cirout {
                position: absolute;
                z-index: 1;
                height: 100px;
                width: 50px;
                overflow: hidden;
                top: 0;
                left: 0;
                transform-origin: 100% 50%;
                transform: rotate(-180deg);
                transition: all 0.3s ease;
                .cir {
                  position: absolute;
                  z-index: 1;
                  width: 100px;
                  height: 100px;
                  border: 2px solid #99b712;
                  border-radius: 120px;
                  box-sizing: border-box;
                  left: 0;
                  top: 0;
                }
              }
            }
            .aniout2 {
              width: 50px;
              height: 100px;
              overflow: hidden;
              position: relative;
              .cirout {
                position: absolute;
                z-index: 1;
                height: 100px;
                width: 50px;
                overflow: hidden;
                top: 0;
                left: 0;
                transform-origin: 0 50%;
                transform: rotate(-180deg);
                transition: all 0.3s ease;
                .cir {
                  position: absolute;
                  z-index: 1;
                  width: 100px;
                  height: 100px;
                  border: 2px solid #99b712;
                  border-radius: 100px;
                  box-sizing: border-box;
                  right: 0;
                  top: 0;
                }
              }
            }
            .videobg {
              filter: brightness(0.7);
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              z-index: 1;
              top: 0;
              left: 0;
            }
          }
          video {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        .r {
          height: 385px;
          overflow: hidden;
          flex: 1;
          position: relative;
          z-index: 1;
          transition: all 1s ease 2s;
          transform: translate(0, -100%);
          opacity: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          max-height: 385px;
          height: 100%;
          &:after {
            content: '';
            display: block;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 160px;
            position: absolute;
            background-image: linear-gradient(to top, #fff 0%, transparent 100%);
            pointer-events: none;
          }
          //  &:before {
          //   content: '';
          //   display: block;
          //   top: 50px;
          //   left: 0;
          //   width: 100%;
          //   height: 60px;
          //   position: absolute;
          //   background-image: linear-gradient(to top, transparent 0%, #fff 100%);
          // }
          .ri {
            max-height: 240px;
            height: 100%;
            overflow: hidden;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
          }
          .gre {
            font-size: 30px;
            font-weight: bold;
            color: #9EC222;
            line-height: 30px;
            margin-bottom: 23px;
          }
          .txt {
            flex: 1;
            width: 100%;
            font-size: 20px;
            font-weight: 500;
            color: #929292;
            line-height: 32px;
            text-align: left;
            padding-bottom: 120px;
            overflow: auto;
            padding-right: 52px;
            box-sizing: border-box;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            &::-webkit-scrollbar {
              display: block;
              width: 9px;
            }
            &::-webkit-scrollbar-track {
              background: #fff;
            }
            &::-webkit-scrollbar-track-piece {
              background: #fff;
            }
            &::-webkit-scrollbar-thumb {
              width: 9px;
              border-radius: 9px;
              background:rgba(245, 245, 245, 1);
            }
          }
        }
      }
      position: absolute;
      z-index: 1;
      top: 0%;
      left: 0;
      transition: all 0.75s ease;
      transform: translate(-100%, 0);
      opacity: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      &.on {
        transition: all 0.75s ease 0.75s;
        transform: translate(0, 0);
        opacity: 1;
        .b {
          .l {
            transform: translate(0, 0);
            opacity: 1;
          }
          .r {
            transform: translate(0, 0);
            opacity: 1;
          }
        }
      }
    }
  }
  .page6 {
    height: 100vh;
    width: 100vw;
    opacity: 0;
    // transform: translate(100px, 0);
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    &.on {
      opacity: 1;
      // transform: translate(0, 0);
    }
    .in {
      flex-direction: column;
      flex: 1;
    }
    .title {
      // margin-top: 123px;
      margin-bottom: 10vh;
      flex: none;
    }
    .picwall {
      width: 100%;
      overflow: hidden;
      flex: none;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      .line {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 60px;
        &:nth-child(2n) {
          margin-left: calc(-100px - 864px);
        }
        .rec {
          min-width: 300px;
          width: 300px;
          height: 125px;
          margin-right: 20px;
          position: relative;
          cursor: pointer;
          img {
            display: block;
            &:nth-child(1) {
              width: 100%;
              height: 100%;
            }
            &:nth-child(2) {
              display: block;
              position: absolute;
              z-index: 1;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%) scale(0.5);
            }
            &.g41{
              width: 100%;
              top: 0;
              left: 0;
              height: 100%;
              transform: none !important;
            }
          }
        }
      }
    }
  }
  .page7 {
    height: calc(100vh + 50px);
    width: 100vw;
    background: #F0F5EF;
    opacity: 0;
    // transform: translate(-100px, 0);
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // overflow: hidden;
    &.on {
      opacity: 1;
      // transform: translate(0, 0);
    }
    .in {
      flex-direction: column;
      // padding-bottom: 47px;
      position: relative;
      align-items: center;
      justify-content: space-evenly;
    }
    .title {
      // margin-top: 0px;
      // margin-bottom: 50px;
      flex: 1;
    }
    .info {
      flex: 6;
    }
    .top {
      position: relative;
      z-index: 1;
      > img {
        width: 1080px;
        height: 300px;
        display: block;
      }
      .txt {
        padding: 40px 60px;
        height: 300px;
        box-sizing: border-box;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 32px;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 50%;
        text-align: left;
        background-color: rgba(45, 45, 45, 0.78);
        overflow: hidden;
      }
    }
    .con {
      font-size: 36px;
      font-weight: bold;
      color: #9EC222;
      // margin-top: 70px;
      width: 100%;
      text-align: left;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      > div {
        width: 100%;
      }
    }
    .line {
      margin-top: 10px;
      .li a:hover {
        opacity: 0.5;
      }
      .l1 .mail {
        display: inline;
        width: auto !important;
      }
      .l1,
      .l1 .mail {
        font-size: 14px;
        color: #000000;
        line-height: 30px;
        text-align: left;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-decoration: none;
        .link {
          display: block;
          height: 28px;
          margin-right: 30px;
          position: relative;
          z-index: 1;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: 19px;
            display: block;
          }
          &:hover {
            img:nth-child(1) {
              opacity: 1;
            }
            img:nth-child(2) {
              opacity: 0;
            }
          }
          img:nth-child(1) {
            opacity: 0;
            position: absolute;
            z-index: 2;
            left: 0;
            top: 0;
            width: 100%;
            // height: 100%;
            transition: all 0.3s ease;
          }
          img:nth-child(2) {
            display: block;
            // height: 100%;
          }
        }
      }
    }
  }
  .bnav {
    height: 50px;
    // padding-bottom: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 1);
    font-size: 12px;
    color: #FFFFFF;
    line-height: 24px;
    // position: absolute;
    // z-index: 1;
    // left: 50%;
    // transform: translate(-50%, 0);
    // width: 100vw;
    // bottom: 0;
    a {
      color: #fff;
      text-decoration: underline;
      margin: 0 10px;
    }
  }
  @mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
      @content;
    }
    @-moz-keyframes #{$animationName} {
      @content;
    }
    @-o-keyframes #{$animationName} {
      @content;
    }
    @keyframes #{$animationName} {
      @content;
    }
  }
  @include keyframes(cir1) {
    0% {
      transform: rotate(-180deg);
    }
    50% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  @include keyframes(cir2) {
    0% {
      transform: rotate(-180deg);
    }
    50% {
      transform: rotate(-180deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  @include keyframes(roll1) {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      z-index: 3;
    }
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      z-index: 2;
    }
    5% {
      transform: translate(calc(-50% - 140px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 1;
    }
    33% {
      transform: translate(calc(-50% - 140px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 1;
    }
    38% {
      transform: translate(calc(-50% + 140px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 1;
    }
    66% {
      transform: translate(calc(-50% + 140px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 2;
    }
    66% {
      transform: translate(calc(-50% + 140px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 3;
    }
    71% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      z-index: 3;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      z-index: 3;
    }
  }
  @include keyframes(roll2) {
    0% {
      transform: translate(calc(-50% - 140px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 1;
    }
    5% {
      transform: translate(calc(-50% + 140px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 1;
    }
    33% {
      transform: translate(calc(-50% + 140px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 2;
    }
    33% {
      transform: translate(calc(-50% + 140px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 3;
    }
    38% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      z-index: 3;
    }
    66% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      z-index: 3;
    }
    66% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      z-index: 2;
    }
    71% {
      transform: translate(calc(-50% - 140px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 1;
    }
    100% {
      transform: translate(calc(-50% - 140px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 1;
    }
  }
  @include keyframes(roll3) {
    0% {
      transform: translate(calc(-50% + 140px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 2;
    }
    0% {
      transform: translate(calc(-50% + 140px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 3;
    }
    5% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      z-index: 3;
    }
    33% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      z-index: 3;
    }
    33% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      z-index: 2;
    }
    38% {
      transform: translate(calc(-50% - 140px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 1;
    }
    66% {
      transform: translate(calc(-50% - 140px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 1;
    }
    71% {
      transform: translate(calc(-50% + 140px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 1;
    }
    100% {
      transform: translate(calc(-50% + 140px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 1;
    }
  }
  @include keyframes(roll4) {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      z-index: 3;
    }
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      z-index: 2;
    }
    5% {
      transform: translate(calc(-50% - 60px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 1;
    }
    33% {
      transform: translate(calc(-50% - 60px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 1;
    }
    38% {
      transform: translate(calc(-50% + 60px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 1;
    }
    66% {
      transform: translate(calc(-50% + 60px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 2;
    }
    66% {
      transform: translate(calc(-50% + 60px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 3;
    }
    71% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      z-index: 3;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      z-index: 3;
    }
  }
  @include keyframes(roll5) {
    0% {
      transform: translate(calc(-50% - 60px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 1;
    }
    5% {
      transform: translate(calc(-50% + 60px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 1;
    }
    33% {
      transform: translate(calc(-50% + 60px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 2;
    }
    33% {
      transform: translate(calc(-50% + 60px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 3;
    }
    38% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      z-index: 3;
    }
    66% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      z-index: 3;
    }
    66% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      z-index: 2;
    }
    71% {
      transform: translate(calc(-50% - 60px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 1;
    }
    100% {
      transform: translate(calc(-50% - 60px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 1;
    }
  }
  @include keyframes(roll6) {
    0% {
      transform: translate(calc(-50% + 60px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 2;
    }
    0% {
      transform: translate(calc(-50% + 60px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 3;
    }
    5% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      z-index: 3;
    }
    33% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      z-index: 3;
    }
    33% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      z-index: 2;
    }
    38% {
      transform: translate(calc(-50% - 60px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 1;
    }
    66% {
      transform: translate(calc(-50% - 60px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 1;
    }
    71% {
      transform: translate(calc(-50% + 60px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 1;
    }
    100% {
      transform: translate(calc(-50% + 60px), -50%) scale(0.8);
      opacity: 0.8;
      z-index: 1;
    }
  }
}
.ttg {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: #333;
  width: 200px;
  border-radius: 3px;
  color: #fff;
  height:40px;
  line-height: 40px;
}
@media screen and (max-width: 1280px) {
  .home {
    .page {
      min-width: 800px;
      min-height: 400px;
    }
    .in {
      width: 800px;
    }
    .side {
      .btn {
        width: 43px;
        height: 43px;
        &.up {
          img {
            height: 20px;
          }
        }
        &.mail {
          img {
            height: 19px;
          }
        }
        &.cs {
          img {
            height: 24px;
          }
        }
        &.app {
          img {
            height: 23px;
          }
        }
        &.pcicon {
          img {
            height: 21px;
          }
        }
        .pop {
          right: 44px;
          .code {
            width: 108px;
            height: 132px;
            img {
              width: 80px;
              height: 80px;
            }
            .codetip {
              font-size: 12px;
            }
          }
          span {
            height: 43px;
            font-size: 12px;
            width: 108px;
          }
        }
      }
    }
    .nav {
      height: 40px;
      .img {
        width: 80px;
        height: 17px;
      }
      .list {
        .btn {
          font-size: 10px;
          font-weight: bold;
          line-height: 40px;
          height: 40px;
          &:after {
            height: 2px;
          }
        }
      }
    }
    .page1 {
      &.on {
        .outbox {
          .inbox {
            transform: translate(0, calc(-100% + 74px));
          }
        }
      }
      .center {
        width: 100vw;
        .bannerout {
          .txt {
              width: 460px;
              font-size: 16px;
              line-height: 28px;
              .btn {
                width: 101px;
                height: 26px;
                font-size: 12px;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                padding: 0;
              }
          }
          .bannerin {
            .item {
              .txt {
                width: 460px;
                font-size: 16px;
                line-height: 28px;
                .btn {
                  width: 101px;
                  height: 26px;
                  font-size: 12px;
                  font-weight: 500;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  box-sizing: border-box;
                  padding: 0;
                }
              }
            }
          }
        }
        .btns {
          width: 800px;
          > .lbtn,
          > .rbtn {
            width: 39px;
            height: 74px;
            > img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .bottom {
        height: 154px;
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 150px;
          margin: 0 33px;
          .data {
              width: 130px;
              height: 74px;
              font-size: 53px;
              font-weight: 500;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #FFFFFF;
              line-height: 74px;
              .outbox {
                height: 74px;
                line-height: 74px;
              }
          }
          .desc {
            width: 160px;
            height: 74px;
            text-align: left;
            font-size: 12px;
            line-height: 20px;
            > img {
              width: 102px;
              height: 71px;
            }
          }
        }
      }
    }
    .page2 {
      .imglist {
        width: 800px;
        .item {
          width: 243px;
          height: 170px;
          margin-bottom: 24px;
          margin-right: 34px;
          &:nth-child(3n) {
            margin-right: 0;
          }
          .tag {
            width: 123px;
            height: 38px;
            padding: 0;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .title {
        font-size: 20px;
        margin-bottom: 40px;
        flex: 1;
        &:before,
        &:after {
          width: 51px;
        }
        &:before {
          margin-right: 18px;
        }
        &:after {
          margin-left: 18px;
        }
      }
      .grays {
        width: 800px;
        flex: 1.5;
        .gray {
          width: 800px;
          align-items: flex-start;
          justify-content: flex-start;
          .tip {
            font-size: 14px;
          }
          .desc {
            font-size: 14px;
          }
        }
      }
    }
    .page3 {
      .imgslide {
        img {
          width: 666px !important;
          height: 487px !important;
        }
      }
    }
    .page4 {
      .video {
        width: 800px;
        height: 428px;
      }
    }
    .page5 {
      .in {
        justify-content: center;
      }
      .tabs {
        width: 800px;
        border-bottom: 5px solid #c1c1c1;
        margin-bottom: 21px;
        .tab {
          font-size: 20px;
          height: 40px;
          line-height: 40px;
          border-bottom-width: 5px;
          margin-bottom: -5px;
          &.on {
          }
        }
      }
      .contents {
        width: 800px;
        .content {
          > img {
            width: 800px;
            height: 217px;
          }
          .b {
            padding: 22px;
            height: 301px;
            .l {
              width: 363px;
              height: 257px;
              margin-right: 38px;
            }
            .r {
              .ri {
                height: 100%;
                max-height: none;
                .gre {
                  font-size: 20px;
                  margin-bottom: 15px;
                }
                .txt {
                  font-size: 14px;
                  line-height: 24px;
                  padding-right: 40px;
                }
              }
            }
          }
        }
      }
    }
    .page6 {
      .title {
        font-size: 20px;
        margin-bottom: 40px;
        &:before,
        &:after {
          width: 51px;
        }
        &:before {
          margin-right: 18px;
        }
        &:after {
          margin-left: 18px;
        }
      }
      .picwall {
        .line {
          &:nth-child(1) {
            margin-bottom: 40px;
          }
          .rec {
            width: 200px;
            height: 83px;
            min-width: 0;
            margin-right: 16px;
            img:nth-child(2) {
              transform: translate(-50%, -50%) scale(0.33);
            }
          }
        }
      }
    }
    .page7 {
      .title {
        font-size: 20px;
        margin-bottom: 33px;
        flex: none;
        &:before,
        &:after {
          width: 51px;
        }
        &:before {
          margin-right: 18px;
        }
        &:after {
          margin-left: 18px;
        }
      }
      .in {
        justify-content: center;
      }
      .info {
        flex: none;
        .top {
          margin-bottom: 47px;
          img {
            width: 800px;
            height: 220px;
          }
          .txt {
            font-size: 14px;
            line-height: 23px;
            padding: 42px 58px;
            height: 220px;
          }
        }
      }
      .con {
        font-size: 26px;
      }
      .line {
        .l1 {
          font-size: 14px;
          line-height: 30px;
          .link {
            margin-right: 23px;
            height: 19px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .home {
    .page {
      min-width: 800px;
      min-height: 400px;
    }
    .in {
      width: 800px;
    }
    .side {
      .btn {
        width: 43px;
        height: 43px;
        &.up {
          img {
            height: 20px;
          }
        }
        &.mail {
          img {
            height: 19px;
          }
        }
        &.cs {
          img {
            height: 24px;
          }
        }
        &.app {
          img {
            height: 23px;
          }
        }
        &.pcicon {
          img {
            height: 21px;
          }
        }
        .pop {
          right: 44px;
          .code {
            width: 108px;
            height: 132px;
            img {
              width: 80px;
              height: 80px;
            }
            .codetip {
              font-size: 12px;
            }
          }
          span {
            height: 43px;
            font-size: 12px;
            width: 108px;
          }
        }
      }
    }
    .nav {
      height: 40px;
      .img {
        width: 80px;
        height: 17px;
      }
      .list {
        .btn {
          font-size: 10px;
          font-weight: bold;
          line-height: 40px;
          height: 40px;
          &:after {
            height: 2px;
          }
        }
      }
    }
    .page1 {
      &.on {
        .outbox {
          .inbox {
            transform: translate(0, calc(-100% + 74px));
          }
        }
      }
      .center {
        width: 100vw;
        .bannerout {
          .txt {
              width: 460px;
              font-size: 16px;
              line-height: 28px;
              .btn {
                width: 101px;
                height: 26px;
                font-size: 12px;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                padding: 0;
              }
          }
          .bannerin {
            .item {
              .txt {
                width: 460px;
                font-size: 16px;
                line-height: 28px;
                .btn {
                  width: 101px;
                  height: 26px;
                  font-size: 12px;
                  font-weight: 500;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  box-sizing: border-box;
                  padding: 0;
                }
              }
            }
          }
        }
        .btns {
          width: 800px;
          > .lbtn,
          > .rbtn {
            width: 39px;
            height: 74px;
            > img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .bottom {
        height: 154px;
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 150px;
          margin: 0 33px;
          .data {
              width: 130px;
              height: 74px;
              font-size: 53px;
              font-weight: 500;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #FFFFFF;
              line-height: 74px;
              .outbox {
                height: 74px;
                line-height: 74px;
              }
          }
          .desc {
            width: 160px;
            height: 74px;
            text-align: left;
            font-size: 12px;
            line-height: 20px;
            > img {
              width: 102px;
              height: 71px;
            }
          }
        }
      }
    }
    .page2 {
      .imglist {
        width: 800px;
        .item {
          width: 243px;
          height: 170px;
          margin-bottom: 24px;
          margin-right: 34px;
          &:nth-child(3n) {
            margin-right: 0;
          }
          .tag {
            width: 123px;
            height: 38px;
            padding: 0;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .title {
        font-size: 20px;
        margin-bottom: 40px;
        flex: 1;
        &:before,
        &:after {
          width: 51px;
        }
        &:before {
          margin-right: 18px;
        }
        &:after {
          margin-left: 18px;
        }
      }
      .grays {
        width: 800px;
        flex: 1.5;
        .gray {
          width: 800px;
          align-items: flex-start;
          justify-content: flex-start;
          .tip {
            font-size: 14px;
          }
          .desc {
            font-size: 14px;
          }
        }
      }
    }
    .page3 {
      .imgslide {
        img {
          width: 666px !important;
          height: 487px !important;
        }
      }
    }
    .page4 {
      .video {
        width: 800px;
        height: 428px;
      }
    }
    .page5 {
      .in {
        justify-content: center;
      }
      .tabs {
        width: 800px;
        border-bottom: 5px solid #c1c1c1;
        margin-bottom: 21px;
        .tab {
          font-size: 20px;
          height: 40px;
          line-height: 40px;
          border-bottom-width: 5px;
          margin-bottom: -5px;
          &.on {
          }
        }
      }
      .contents {
        width: 800px;
        .content {
          > img {
            width: 800px;
            height: 217px;
          }
          .b {
            padding: 22px;
            height: 301px;
            .l {
              width: 363px;
              height: 257px;
              margin-right: 38px;
            }
            .r {
              .ri {
                height: 100%;
                max-height: none;
                .gre {
                  font-size: 20px;
                  margin-bottom: 15px;
                }
                .txt {
                  font-size: 14px;
                  line-height: 24px;
                  padding-right: 40px;
                }
              }
            }
          }
        }
      }
    }
    .page6 {
      .title {
        font-size: 20px;
        margin-bottom: 40px;
        &:before,
        &:after {
          width: 51px;
        }
        &:before {
          margin-right: 18px;
        }
        &:after {
          margin-left: 18px;
        }
      }
      .picwall {
        .line {
          &:nth-child(1) {
            margin-bottom: 40px;
          }
          .rec {
            width: 200px;
            height: 83px;
            min-width: 0;
            margin-right: 16px;
            img:nth-child(2) {
              transform: translate(-50%, -50%) scale(0.33);
            }
          }
        }
      }
    }
    .page7 {
      .title {
        font-size: 20px;
        margin-bottom: 33px;
        flex: none;
        &:before,
        &:after {
          width: 51px;
        }
        &:before {
          margin-right: 18px;
        }
        &:after {
          margin-left: 18px;
        }
      }
      .in {
        justify-content: center;
      }
      .info {
        flex: none;
        .top {
          margin-bottom: 47px;
          img {
            width: 800px;
            height: 220px;
          }
          .txt {
            font-size: 14px;
            line-height: 23px;
            padding: 42px 58px;
            height: 220px;
          }
        }
      }
      .con {
        font-size: 26px;
      }
      .line {
        .l1 {
          font-size: 14px;
          line-height: 30px;
          .link {
            margin-right: 23px;
            height: 19px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .home {
    height: auto;
    .pc {
      display: none !important;
    }
    .h5 {
      display: block !important;
    }
    .a1 {
      display: none;
    }
    .side {
      display: none;
    }
    .page {
      min-width: 0;
      min-height: 0;
      .in {
        padding: 0;
        width: 100%;
        height: 100%;
        flex: none;
      }
    }
    .page1 {
      opacity: 1;
      height: auto;
      position: relative;
      z-index: 9;
      .nav {
        .tline {
          position: absolute;
          left: 14px;
          top: 10px;
          z-index: 1;
          div {
            width: 19px;
            height: 2px;
            background: #fff;
            margin: 4px 0;
          }
        }
        .glass {
          transform: translate(-100%, 0);
          opacity: 0;
          transition: all 0.3s ease;
          position: absolute;
          z-index: 998;
          left: 0;
          top: 0;
          width: 100vw;
          height: 100vh;
          backdrop-filter: blur(5px);
          &.on {
            transform: translate(0, 0);
            opacity: 1;
          }
          img {
            width: 35px;
            height: 35px;
            top: 21px;
            right: 21px;
            position: absolute;
            z-index: 1;
            background: rgba(0,0,0,.3);
            border-radius: 35px;
            &.hover {
              box-shadow: 0 0 5px rgba(0,0,0,.5);
            }
          }
        }
        .list {
          transform: translate(-100%, 0);
          opacity: 0;
          transition: all 0.3s ease;
          &.on {
            transform: translate(0, 0);
            opacity: 1;
          }
          &.hide {
            display: none;
          }
          display: flex;
          position: absolute;
          z-index: 999;
          left: 0;
          top: 0;
          flex-direction: column;
          height: 100vh;
          background: rgba(43, 43, 43, 0.8);
          width: 294px;
          backdrop-filter: blur(5px);
          justify-content: flex-start;
          .img {
            height: 39px;
            width: 170px;
            margin-bottom: 70px;
            margin-top: 90px;
          }
          .btn {
            width: 100%;
            height: 64px;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 18px;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            box-sizing: border-box;
            padding-left: 88px;
            opacity: 1;
            img {
              height: 20px;
              margin-right: 17px;
            }
            &.on,
            &:hover {
              border-bottom: none;
              background: rgba(158, 194, 34, 1);
              &:after {
                display: none;
              }
            }
          }
        }
      }
      .center {
        flex: none;
        height: 211px;
        margin-top: 40px;
        .btns {
          display: none;
        }
        .bannerout {
          position: relative;
          .txt {
            font-size: 12px;
            color: #FFFFFF;
            line-height: 18px;
            padding: 47px 24px 0 24px;
            margin: 0;
            position: absolute;
            z-index: 9;
            top: 0;
            left: 0;
            right: 0;
            transform: none;
            box-sizing: border-box;
            width: 100%;
            .tx {
              width: 98px;
              height: 21px;
              margin: 0 0 10px 0;
            }
            .btn {
              display: none;
            }
          }
          .bannerin {
            .item {
              &:before {
                display: none;
              }
              >img {
                object-fit: fill;
                height: 100%;
              }
            }
          }
        }
      }
      &.on {
        .bottom {
          .item {
            .data {
              .outbox {
                .inbox {
                  transform: translate(0, calc(-100% + 32px));
                }
              }
            }
          }
        }
      }
      .bottom {
        height: 85px;
        background: rgba(25,25,25,1);
        .item {
          width: 50px;
          margin: 0;
          flex: 1;
          .data {
            font-size: 23px;
            line-height: 32px;
            height: 32px;
            .outbox {
              height: 32px;
              line-height: 32px;
              .inbox {
                // transform: translate(0, calc(-100% + 32px));
              }
            }
          }
          .desc {
            font-size: 12px;
            line-height: 16px;
            font-weight: 500;
            color: #8E8E8E;
            width: 100%;
            transform: scale(0.5);
            transform-origin: 50% 50%;
            white-space: nowrap;
            height: 29px;
            margin-top: 12px;
            > img {
              width: 42px;
              height: 29px;
              transform: scale(2) translate(-25%,-25%);
              transform-origin: 50% 50%;
            }
          }
        }
      }
    }
    .page2 {
      padding-top: 20px;
      opacity: 1;
      width: 100%;
      height: auto;
      .in {
        align-items: flex-start;
        .title {
          display: none;
        }
      }
      .imglistout {
        width: 100vw;
        overflow: auto;
      }
      .imglist {
        flex-wrap: nowrap;
        width: calc(148px * 6 + 14px * 6);
        .item {
          margin-right: 14px;
          background: transparent;
          height: auto;
          margin-bottom: 0;
          width: auto;
          &:nth-child(3n) {
            margin-right: 14px;
          }
          &.on {
            .tag {
              color: rgba(158, 194, 34, 1);
              opacity: 1;
            }
          }
          &:before {
            width: 148px;
            height: 103px;
          }
          .imgblock {
            width: 148px;
            height: 103px;
            background: #9ec222;
            margin-right: 0;
            > img {
              display: block;
            }
          }
          .tag {
            background: none;
            position: static;
            transform: none;
            margin: 0;
            width: 100%;
            font-size: 14px;
            color: #DFDFDF;
            opacity: 1;
          }
        }
      }
      .grays {
        width: 100vw;
        height: 120px;
        flex: none;
        .gray {
          display: none;
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          font-size: 14px;
          color: #777777;
          .tip {
            display: none;
          }
          &.on {
            display: flex;
          }
        }
      }
    }
    .page3 {
      opacity: 1;
      width: 100%;
      height: 263px;
      background: none;
      .imgslide {
        width: auto;
        height: auto;
        img {
          width: 279px !important;
          height: 203px !important;
          &:nth-child(1) {
            animation: roll4 12s linear infinite;
          }
          &:nth-child(2) {
            animation: roll5 12s linear infinite;
          }
          &:nth-child(3) {
            animation: roll6 12s linear infinite;
          }
        }
      }
    }
    .page4 {
      opacity: 1;
      height: auto;
      .video {
        height: 200px;
      }
    }
    .page5 {
      opacity: 1;
      background: #fff;
      height: auto;
      padding-bottom: 16px;
      .tabs {
        width: calc(100% - 20px);
        overflow: auto;
        display: flex;
        justify-content: flex-start;
        box-sizing: border-box;
        margin-left: 20px;
        // border-bottom: 3px solid rgba(156, 167, 158, 0.3);
        border: none;
        height: 44px;
        overflow-y: hidden;
        margin-bottom: 9px;
        position: relative;
        &:after {
          content: '';
          display: block;
          position: absolute;
          z-index: 1;
          bottom: 2px;
          left: 0;
          height: 3px;
          width: 100%;
          background: #ccc;
        }
        .tab {
          font-size: 14px;
          flex: none;
          margin: 0 16px;
          font-weight: normal;
          color: #989898;
          position: relative;
          z-index: 3;
          &.on {
            border-bottom: 3px solid #9EC222;
          }
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
          &.on {
            color: #9EC222;
          }
        }
      }
      .contents {
        margin: 0 15px;
        width: calc(100% - 30px);
        height: 414px;
        .content {
          width: 100%;
          > img {
            width: 100%;
            height: auto;
          }
          .b {
            padding: 0;
            flex-direction: column-reverse;
            height: auto;
            .r {
              .ri {
                .gre {
                  display: none;
                }
                .txt {
                  font-size: 14px;
                  color: #A8A8A8;
                  line-height: 20px;
                  height: 103px;
                  flex: none;
                  padding-right: 17px;
                  padding-bottom: 0;
                  margin: 11px 0;
                  &::-webkit-scrollbar {
                      display: block;
                    width: 2px;
                  }
                  &::-webkit-scrollbar-track {
                    background: rgba(181, 181, 181, 0.26);
                  }
                  &::-webkit-scrollbar-track-piece {
                    background: rgba(181, 181, 181, 0.26);
                  }
                  &::-webkit-scrollbar-thumb {
                    width: 4px;
                    border-radius: 4px;
                    background:rgba(80, 80, 80, 0.49)
                  }
                }
              }
              &:after {
                display: none;
              }
            }
            .l {
              margin-right: 0;
              height: auto;
              width: 100%;
            }
          }
        }
      }
    }
    .page6 {
      opacity: 1;
      height: auto;
      background-color: rgba(243, 243, 243, 1);
      .in {
        margin-top: 20px;
        background: #fff;
        padding: 20px 0;
      }
      .title {
        font-size: 16px;
        color: #9EC222;
        line-height: 22px;
        margin-bottom: 0;
        &:before {
          display: none;
        }
        &:after {
          display: none;
        }
      }
      .picwall {
        transform: scale(0.5, 0.5);
        width: calc(200% - 40px);
        margin: -40px 0 -60px 0;
        .line {
          margin-bottom: 20px !important;
        }
      }
    }
    .page7 {
      opacity: 1;
      height: auto;
      background: rgba(0, 0, 0, 1);
      .title {
        display: none;
      }
      .info {
        width: 100%;
        .top {
          height: 192px;
          width: 100%;
          overflow: hidden;
          margin-bottom: 0;
          > img {
            // width: 100%;
            height: 100%;
            width: 100%;
            margin-right: 0;
          }
          .txt {
            .tttt {
              font-size: 16px;
              color: #FFFFFF;
              line-height: 22px;
              text-align: center;
              margin-bottom: 20px;
            }
            left: 0;
            background: none;
            padding: 30px 15px;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 24px;
          }
        }
        .con {
          font-size: 18px;
          font-weight: bold;
          color: #9EC222;
          line-height: 19px;
          padding: 0 16px;
          margin: 30px 0 21px 0;
        }
        .line {
          padding: 0 12px 0 12px;
          margin-bottom: 30px;
          .l1 {
            font-size: 14px;
            color: #FFFFFF;
            line-height: 28px;
            align-items: flex-start;
            > a {
              font-size: 14px;
              color: #FFFFFF;
              line-height: 28px;
            }
            > span {
              min-width: 100px;
              display: flex;
              > span {
                  flex: 1;
                  display: block;
                  text-align-last: justify;
              }
            }
            .link {
              margin-right: 17px;
              margin-top: 5px;
              img {
                height: 14px;
              }
              img:nth-child(2) {
                filter: invert(1);
              }
            }
          }
        }
      }
      .bnav {
        display: block;
        a {
          display: inline;
          margin: 0;
          text-decoration: none;
        }
        font-size: 10px;
        color: #FFFFFF;
        line-height: 13px;
      }
    }
  }
}
</style>
